import React, { Component } from 'react'
import { withRouter, Link } from "react-router-dom";
import { Image, Nav, Accordion } from 'react-bootstrap'
import { Scrollbars } from 'react-custom-scrollbars'
import logo from '../../../images/logo.svg'
import small_logo from '../../../images/small-logo.png';
import * as Cookies from "es-cookie";
import gifImage from "../../../images/new-gif-icon.png";
import pulseImage from "../../../images/pulse.png";
import './_navbars.scss';
import StartingModal from '../../components/home/starting_modal/MobileStartingModal';

import HomeEamcet from "../../../images/images1/home_eamcet.png"
import IpeLectures from "../../../images/images1/ipe_classes.png"
import MockTest from "../../../images/images1/mocktest_eamcet.png"
import Quiz from "../../../images/images1/quiz_eamcet.png"
import PreviousPapers from "../../../images/images1/practisetest_eamcet.png"
import ShortNotes from "../../../images/images1/shortnotes_eamcet.png"
import Formulas from "../../../images/images1/formula_eamcet.png"
import Subscriptions from "../../../images/images1/subscription_eamcet.png"
import PractiseTest from "../../../images/images1/practisetest_eamcet.png"
import BlogEamcet from "../../../images/images1/blog_eamcet.png"
import BluePrint from "../../../images/images1/eamcet_blue_print.png";
import CrashCourse from "../../../images/images1/eamcet_crash_course.png";
import EamcetPrevious from "../../../images/images1/eamcet_prev_paper.png";
import RankPredictor from "../../../images/images1/eamcet_rankPredictor.png";
import ReactGA from 'react-ga';
import { GoogleAnalyticsArray } from '../../pages/GoogleAnalytics';
import Foundation from '../../../images/images1/foundationmenu.png';
import foundationNavIcon from '../../../images/foundation logo white.png'
import foundationtest from '../../../images/images1/foundation_test.png'
import longtermCourse from '../../../images/images1/longtermcourse.png'
import partnerOffers from '../../../images/images1/partner_offers.png'

import { graphql } from "react-apollo";
import gql from "graphql-tag";
import * as compose from 'lodash.flowright';
import StudentTargettingPopup from './StudentTargettingPopup'

const GET_CROSSSELLING_POPUP = gql`
query($mobile:String,$app_module_id:Int,$source:Int){
    getCrossSellingEnabled(mobile: $mobile, app_module_id: $app_module_id, source: $source) {
        pop_enabled
        mobile_image_path
        web_image_path
        navigation_url
        button_name
      }
}
`;


const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
        borderRadius: 6,
        width: '3px',
        backgroundColor: 'rgba(255, 255, 255, 0.3)'
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

class MobileAsideNavbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            StartingModalShow: false,
            targetPopup: true,
            targetPopupData: {},
        }
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            this.isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));

        }
    }
    componentDidMount = () => {
        console.log("localStorage.getItem", localStorage.getItem("homemodal"));
        if (localStorage.getItem("homemodal") == "true") {
            this.setState({
                StartingModalShow: true
            })
        }
    }
    onHide = () => {
        console.log("onHide");
        this.setState({ StartingModalShow: false, targetPopup: false });
        localStorage.setItem("homemodal", "false");
    }
    logout = () => {
        if (Cookies.get("studenttoken") !== undefined) {
            Cookies.remove("studenttoken");
            Cookies.remove("studentrefreshtoken");
            Cookies.remove("studentusername");
            Cookies.remove("studentemail");

            Cookies.remove("mobile");
            Cookies.remove("classid");
            Cookies.remove("examid");
            Cookies.remove("exam_name");
            Cookies.remove("mobileverified");
            Cookies.remove("targetyear");
            Cookies.remove("videos");
            Cookies.remove("branch_name");
            Cookies.remove("role", "");
            Cookies.remove("profile_pic");
            Cookies.remove("student_userlevel");
            Cookies.remove("stulogintype", "");
            localStorage.removeItem('profile_pic');
            localStorage.removeItem('studentglobals');
            localStorage.removeItem('globalsubjects');
            localStorage.removeItem("packageplan");
            this.props.history.push("/student/login");
        }
        //this.props.onClick();
    };
    getAccordianActive = () => {
        switch (this.props.history.location.pathname) {

            // Analysis
            case "/student/result-analysis":
                return 1;
            case "/student/practice-exam-analysis":
                return 1;
            case "/student/strength-analysis":
                return 1;
            case "/student/time-analysis":
                return 1;
            case "/student/complexity-analysis":
                return 1;
            case "/student/error-analysis":
                return 1;
            case "/student/questiontype-analysis":
                return 1;
            case "/student/get-ready-for-exam":
                return 2;
            case "/student/bookmark":
                return 2;
            case "/student/notes":
                return 2;
            case "/student/practise_test":
                return 2;
            case "/student/Chapter-wise-questions":
                return 2;
            case "/student/StateShortNotes":
                return 2;
            case "/student/FormulaeSheets":
                return 2;
            case "/student/StateBluePrint":
                return 2;
            case "/student/Eamcet-Video-classes":
                return 2;
            case "/student/institute-revison-materials":
                return 2;
            default: return 0;
        }
    }

    getActiveClassTwo = (link) => {
        if (link === this.props.history.location.pathname)
            return "collapse-item active";
        else
            return "collapse-item";
    }

    getActiveClass = (link) => {
        if (link === this.props.history.location.pathname)
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/subjects" && link === "/student/learn-practice")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/liveClasses" && link === "/student/liveClasses")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/rapidRevision/liveClasses" && link === "/student/rapidRevision/liveClasses")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/BluePrint" && link === "/student/BluePrint")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/syllabus" && link === "/student/syllabus")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/PreviousPapers" && link === "/student/PreviousPapers")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/ShortNotes" && link === "/student/ShortNotes")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/subject/topics" && link === "/student/learn-practice")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/pre-exam-test" && link === "/student/learn-practice")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/subject/chapter" && link === "/student/learn-practice")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/subject/chapter-status" && link === "/student/learn-practice")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/subject/practice-exam-history" && link === "/student/learn-practice")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/subject/start-watching" && link === "/student/learn-practice")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/subject/start-learning" && link === "/student/learn-practice")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/subject/short-notes" && link === "/student/learn-practice")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/subject/start-error-exam" && link === "/student/learn-practice")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/subject/start-error-exam/error-exam" && link === "/student/learn-practic")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/exams/schedule-exam" && link === "/student/exams/schedule-exam")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/exams/adaptive-exam" && link === "/student/exams")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/exams/custom-exam" && link === "/student/exams")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/exams/error-exam" && link === "/student/exams/error-exam")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/videos/recently-watched" && link === "/student/videos")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/exams/previous-paper-exam" && link === "/student/exams")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/exams/custom-previous-paper-exam" && link === "/student/exams")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/get-ready-for-exam/chapter-exam" && link === "/student/get-ready-for-exam")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/get-ready-for-exam/cumulative-exam" && link === "/student/get-ready-for-exam")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/get-ready-for-exam/semigrand-exam" && link === "/student/get-ready-for-exam")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/get-ready-for-exam/grand-exam" && link === "/student/get-ready-for-exam")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/get-ready-for-exam/get-ready-shortnotes-and-materials" && link === "/student/get-ready-for-exam")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/get-ready-for-exam/get-ready-shortnotes/single-shortnote" && link === "/student/get-ready-for-exam")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/get-ready-for-exam/history" && link === "/student/get-ready-for-exam")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/bookmark/videos" && link === "/student/bookmark")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/bookmark/videos/watch-video" && link === "/student/bookmark")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/bookmark/shortnotes-and-materials" && link === "/student/bookmark")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/bookmark/shortnotes-and-material/single-shortnote" && link === "/student/bookmark")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/bookmark/practice-questions" && link === "/student/bookmark")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/bookmark/exam-questions" && link === "/student/bookmark")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/notes/videos" && link === "/student/notes")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/notes/videos/watch-video" && link === "/student/notes")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/notes/shortnotes-and-materials" && link === "/student/notes")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/notes/shortnotes-and-material/single-shortnote" && link === "/student/notes")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/notes/shortnotes-and-material/single-revisions-material" && link === "/student/notes")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/notes/practice-questions" && link === "/student/notes")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/notes/exam-questions" && link === "/student/notes")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/revision-material-groups/custom-revision-materials" && link === "/student/revision-material-groups")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/revision-material-groups/custom-single-revision-material" && link === "/student/revision-material-groups")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/exams/custom-practise-exam" && link === "/student/exams/custom-practise-exam")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/student-blog/student-blog-view" && link === "/student/student-blog")
            return "nav-link active";

        else if (this.props.history.location.pathname === "/student/featuredvideos" && link === "/student/featuredvideos")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/createpreviouspaperexam" && link === "/student/createpreviouspaperexam")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/category_wise_videos" && link === "/student/category_wise_videos")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/Neet_Rise_Course" && link === "/student/Neet_Rise_Course")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/subPlans" && link === "/student/subPlans")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/cbse" && link === "/student/cbse")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/Neet_Rise_Course/liveClass_sessions" && link === "/student/Neet_Rise_Course")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/Neet_Rise_Course/exams" && link === "/student/Neet_Rise_Course")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/IpeClasses" && link === "/student/IpeClasses")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/Neet_Crash_Course" && link === "/student/Neet_Crash_Course")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/Neet_Crash_Course/liveClass_sessions" && link === "/student/Neet_Crash_Course")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/live_Test_series/exams" && link === "/student/live_Test_series/exams")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/practise_test" && link === "/student/practise_test")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/StateShortNotes" && link === "/student/chapter-learning")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/FormulaeSheets" && link === "/student/chapter-learning")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/Mocktests" && link === "/student/Mocktests")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/eamcet-crashcourse" && link === "/student/eamcet-crashcourse")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/eamcet-crashcourse/liveclasses" && link === "/student/eamcet-crashcourse")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/eamcet-crashcourse/exams" && link === "/student/eamcet-crashcourse")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/StatePreviousPapers" && link === "/student/StatePreviousPapers")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/plans_section" && link === "/student/plans_section")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/StateBluePrint" && link === "/student/chapter-learning")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/college-rank-predictor" && link === "/student/college-rank-predictor")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/foundation-course/home" && link === "/student/foundation-course/home")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/eamcet-longterm-course" && link === "/student/eamcet-longterm-course")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/eamcet-longterm-course/liveclasses" && link === "/student/eamcet-longterm-course")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/eamcet-longterm-course/exams" && link === "/student/eamcet-longterm-course")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/ready-to-foundation-tests" && link === "/student/ready-to-foundation-tests")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/Rizee-partner-offers" && link === "/student/Rizee-partner-offers")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/NTA-College_predictor" && link === "/student/NTA-College_predictor")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/Chapter-wise-questions" && link === "/student/chapter-learning")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/Ipe-question-papers" && link === "/student/Ipe-question-papers")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/Eamcet-Video-classes" && link === "/student/Eamcet-Video-classes")
            return "nav-link active";
        else if (this.props.history.location.pathname === "/student/institute-revison-materials" && link === "/student/institute-revison-materials")
            return "nav-link active";
        else
            return "nav-link";
    }

    forumLink = () => {
        const title = GoogleAnalyticsArray[0].Doubts_App;
        ReactGA.pageview(Cookies.get("forumlink"), ["rizee"], title);

        window.open(Cookies.get("forumlink"), "_blank")
    }
    render() {
        const getCrossSellingEnabled = this.props.getCrossSellingEnabled;
        const loading = getCrossSellingEnabled.loading;
        const error = getCrossSellingEnabled.error;

        console.log("this.props.getCrossSellingEnabled", this.props.getCrossSellingEnabled)

        let data = {};

        if (!loading) {
            data = getCrossSellingEnabled.getCrossSellingEnabled;
        }

        this.state.targetPopupData = data;



        let isStudentUserValid = "";
        if (JSON.parse(localStorage.getItem("isStudentUserValid")) != "") {
            isStudentUserValid = JSON.parse(localStorage.getItem("isStudentUserValid"));
        }
        else {
            this.props.history.push("/student/login");
        }
        const isuserValid = JSON.parse(
            isStudentUserValid.user_access_restictions
        );

        let partnerAccess = false;

        if (isuserValid.referred_mobile == "gsl635") {
            partnerAccess = true
        }


        return (
            <React.Fragment>
                <div className="sidebar-header mb-0 px-2 py-2">
                    {
                        partnerAccess == false ? (
                            <Link
                                to="/student/home"
                                className={this.getActiveClass("/student/home")}>
                                {/* <Image className="logo" src={logo} width="100" alt="Image" />
                            <Image className="small_logo" src={small_logo} width="40" style={{ marginLeft: -22 }} alt="Image" /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="159.501" height="37.139" className="logo"
                                    viewBox="0 0 159.501 37.139">
                                    <g transform="translate(-384.741 -406.553)">
                                        <g transform="translate(445.845 406.553)">
                                            <path fill="#ffffff"
                                                d="M841.627,431.867l-4.882-7.052h-5.388v7.052H825.5V406.553h10.958a14.07,14.07,0,0,1,5.84,1.121,8.683,8.683,0,0,1,3.815,3.182,8.772,8.772,0,0,1,1.338,4.882,8.247,8.247,0,0,1-5.208,7.992l5.678,8.137Zm-1.483-19.4a6.155,6.155,0,0,0-4.014-1.139h-4.774v8.824h4.774a6.085,6.085,0,0,0,4.014-1.157,4.563,4.563,0,0,0,0-6.528Z"
                                                transform="translate(-825.498 -406.553)" />
                                            <path fill="#ffffff"
                                                d="M1010.373,411.941a3.063,3.063,0,0,1,0-4.484,3.58,3.58,0,0,1,2.531-.9,3.668,3.668,0,0,1,2.531.868,2.783,2.783,0,0,1,.976,2.17,3.071,3.071,0,0,1-.976,2.332,3.537,3.537,0,0,1-2.531.922A3.577,3.577,0,0,1,1010.373,411.941Zm-.289,3.048h5.641v16.878h-5.641Z"
                                                transform="translate(-983.902 -406.553)" />
                                            <path fill="#ffffff"
                                                d="M1100.574,463.929v4.34h-17.358v-3.4l9.909-11.717h-9.656v-4.34h16.78v3.4l-9.909,11.717Z"
                                                transform="translate(-1047.488 -442.954)" />
                                            <path fill="#ffffff"
                                                d="M1242.651,458.334h-14.719a4.58,4.58,0,0,0,1.881,2.857,6.225,6.225,0,0,0,3.689,1.049,7.459,7.459,0,0,0,2.694-.452,6.529,6.529,0,0,0,2.188-1.428l3,3.255q-2.749,3.146-8.028,3.146a12.694,12.694,0,0,1-5.822-1.284,9.406,9.406,0,0,1-3.906-3.562,9.809,9.809,0,0,1-1.374-5.171,9.943,9.943,0,0,1,1.356-5.153,9.5,9.5,0,0,1,3.725-3.58,11.533,11.533,0,0,1,10.469-.054,8.983,8.983,0,0,1,3.634,3.526,10.518,10.518,0,0,1,1.32,5.334Q1242.76,456.924,1242.651,458.334Zm-13.2-6.256a4.618,4.618,0,0,0-1.591,2.965h9.583a4.667,4.667,0,0,0-1.591-2.947,4.758,4.758,0,0,0-3.182-1.1A4.858,4.858,0,0,0,1229.451,452.078Z"
                                                transform="translate(-1167.251 -441.157)" />
                                            <path fill="#ffffff"
                                                d="M1407.25,458.334h-14.719a4.58,4.58,0,0,0,1.881,2.857,6.225,6.225,0,0,0,3.689,1.049,7.459,7.459,0,0,0,2.694-.452,6.53,6.53,0,0,0,2.188-1.428l3,3.255q-2.749,3.146-8.028,3.146a12.693,12.693,0,0,1-5.822-1.284,9.4,9.4,0,0,1-3.906-3.562,9.809,9.809,0,0,1-1.374-5.171,9.943,9.943,0,0,1,1.356-5.153,9.5,9.5,0,0,1,3.725-3.58,11.533,11.533,0,0,1,10.469-.054,8.983,8.983,0,0,1,3.634,3.526,10.518,10.518,0,0,1,1.32,5.334Q1407.359,456.924,1407.25,458.334Zm-13.2-6.256a4.618,4.618,0,0,0-1.591,2.965h9.583a4.667,4.667,0,0,0-1.591-2.947,4.759,4.759,0,0,0-3.182-1.1A4.858,4.858,0,0,0,1394.051,452.078Z"
                                                transform="translate(-1309.031 -441.157)" />
                                        </g>
                                        <g transform="translate(445.797 439.343)">
                                            <path fill="#ffffff"
                                                d="M826.554,644.174h-1.4v-.664h3.588v.664h-1.4v3.564h-.785Z"
                                                transform="translate(-825.153 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M874.312,643.51v4.228h-.785v-1.812H871.34v1.812h-.785V643.51h.785v1.746h2.187V643.51Z"
                                                transform="translate(-864.261 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M924.272,647.08v.658H921.1V643.51h3.087v.659h-2.3v1.1h2.042v.646h-2.042v1.166Z"
                                                transform="translate(-907.798 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M995.518,643.691a1.394,1.394,0,0,1,.625.52,1.587,1.587,0,0,1,0,1.6,1.386,1.386,0,0,1-.625.522,2.338,2.338,0,0,1-.957.181h-.955v1.22h-.785V643.51h1.74A2.343,2.343,0,0,1,995.518,643.691Zm-.214,1.945a.861.861,0,0,0,0-1.245,1.206,1.206,0,0,0-.779-.218h-.918v1.679h.918A1.208,1.208,0,0,0,995.3,645.636Z"
                                                transform="translate(-969.577 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M1042.442,647.08v.658h-3.172V643.51h3.087v.659h-2.3v1.1h2.042v.646h-2.042v1.166Z"
                                                transform="translate(-1009.586 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M1086.481,647.738l-.864-1.238q-.055.006-.163.006h-.954v1.232h-.785V643.51h1.74a2.345,2.345,0,0,1,.957.181,1.393,1.393,0,0,1,.625.52,1.452,1.452,0,0,1,.218.8,1.434,1.434,0,0,1-.233.822,1.386,1.386,0,0,1-.667.513l.973,1.389Zm-.284-3.346a1.206,1.206,0,0,0-.779-.218h-.918v1.686h.918a1.2,1.2,0,0,0,.779-.221.767.767,0,0,0,.266-.625A.758.758,0,0,0,1086.2,644.392Z"
                                                transform="translate(-1047.869 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M1131.6,644.169v1.286h2.042v.665H1131.6v1.619h-.785V643.51h3.087v.659Z"
                                                transform="translate(-1088.442 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M1176.994,647.08v.658h-3.172V643.51h3.087v.659h-2.3v1.1h2.042v.646h-2.042v1.166Z"
                                                transform="translate(-1125.485 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M1217.059,647.142a2.071,2.071,0,0,1-.809-.776,2.266,2.266,0,0,1,0-2.235,2.092,2.092,0,0,1,.812-.776,2.381,2.381,0,0,1,1.157-.281,2.418,2.418,0,0,1,.949.181,1.94,1.94,0,0,1,.725.526l-.507.477a1.481,1.481,0,0,0-1.13-.5,1.584,1.584,0,0,0-.779.19,1.372,1.372,0,0,0-.538.528,1.62,1.62,0,0,0,0,1.535,1.371,1.371,0,0,0,.538.528,1.584,1.584,0,0,0,.779.19,1.472,1.472,0,0,0,1.13-.5l.507.483a1.927,1.927,0,0,1-.728.525,2.432,2.432,0,0,1-.951.181A2.382,2.382,0,0,1,1217.059,647.142Z"
                                                transform="translate(-1161.778 -643.074)" />
                                            <path fill="#ffffff" d="M1262.2,644.174h-1.4v-.664h3.588v.664h-1.4v3.564h-.785Z"
                                                transform="translate(-1200.4 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M1334.483,645.2h.743v1.685a2.362,2.362,0,0,1-.761.4,2.927,2.927,0,0,1-.894.139,2.411,2.411,0,0,1-1.166-.281,2.085,2.085,0,0,1-.815-.776,2.257,2.257,0,0,1,0-2.235,2.08,2.08,0,0,1,.818-.776,2.441,2.441,0,0,1,1.175-.281,2.568,2.568,0,0,1,.967.175,1.9,1.9,0,0,1,.731.513l-.5.483a1.589,1.589,0,0,0-1.166-.483,1.647,1.647,0,0,0-.794.187,1.362,1.362,0,0,0-.543.525,1.511,1.511,0,0,0-.2.773,1.487,1.487,0,0,0,.2.761,1.4,1.4,0,0,0,.543.532,1.594,1.594,0,0,0,.788.193,1.7,1.7,0,0,0,.87-.218Z"
                                                transform="translate(-1261.126 -643.074)" />
                                            <path fill="#ffffff"
                                                d="M1382.59,647.306a1.915,1.915,0,0,1-.489-1.411V643.51h.785v2.356q0,1.245,1.075,1.245t1.069-1.245V643.51h.773V645.9a1.923,1.923,0,0,1-.486,1.411,2.134,2.134,0,0,1-2.727,0Z"
                                                transform="translate(-1304.889 -643.45)" />
                                            <path fill="#ffffff" d="M1431.991,643.51h.785v4.228h-.785Z"
                                                transform="translate(-1347.863 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M1461.141,643.51h1.848a2.652,2.652,0,0,1,1.2.263,1.946,1.946,0,0,1,.816.743,2.265,2.265,0,0,1,0,2.217,1.945,1.945,0,0,1-.816.743,2.652,2.652,0,0,1-1.2.263h-1.848Zm1.812,3.564a1.8,1.8,0,0,0,.818-.178,1.276,1.276,0,0,0,.544-.508,1.63,1.63,0,0,0,0-1.528,1.277,1.277,0,0,0-.544-.508,1.794,1.794,0,0,0-.818-.178h-1.027v2.9Z"
                                                transform="translate(-1372.971 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M1515.553,647.08v.658h-3.172V643.51h3.087v.659h-2.3v1.1h2.042v.646h-2.042v1.166Z"
                                                transform="translate(-1417.109 -643.45)" />
                                        </g>
                                        <g transform="translate(384.741 406.948)">
                                            <path fill="#ffffff"
                                                d="M384.741,446.083,400.6,418.829h-6.84l5.6-9.431h12.954l20.105,36.684H421.843L408.578,423.6l-12.851,22.486Z"
                                                transform="translate(-384.741 -409.399)" />
                                            <path fill="#f9c52d" d="M601.982,409.4h21.872l-10.936,19.118Z"
                                                transform="translate(-571.865 -409.399)" />
                                        </g>
                                    </g>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97 69" width="40" className="small_logo" style={{ marginLeft: -6 }}>
                                    <g id="Layer">
                                        <path fill="#ffffff" d="M0.29 68.12L29.73 17.51L17.03 17.51L27.42 0L51.48 0L88.82 68.13L69.19 68.13L44.55 26.37L20.69 68.13L0.29 68.12Z" />
                                        <path fill="#f9c52d" d="M56.22 0L96.83 0L76.53 35.5L56.22 0Z" />
                                    </g>
                                </svg>
                            </Link>
                        ) : (
                            <>
                                <svg xmlns="http://www.w3.org/2000/svg" width="159.501" height="37.139" className="logo"
                                    viewBox="0 0 159.501 37.139">
                                    <g transform="translate(-384.741 -406.553)">
                                        <g transform="translate(445.845 406.553)">
                                            <path fill="#ffffff"
                                                d="M841.627,431.867l-4.882-7.052h-5.388v7.052H825.5V406.553h10.958a14.07,14.07,0,0,1,5.84,1.121,8.683,8.683,0,0,1,3.815,3.182,8.772,8.772,0,0,1,1.338,4.882,8.247,8.247,0,0,1-5.208,7.992l5.678,8.137Zm-1.483-19.4a6.155,6.155,0,0,0-4.014-1.139h-4.774v8.824h4.774a6.085,6.085,0,0,0,4.014-1.157,4.563,4.563,0,0,0,0-6.528Z"
                                                transform="translate(-825.498 -406.553)" />
                                            <path fill="#ffffff"
                                                d="M1010.373,411.941a3.063,3.063,0,0,1,0-4.484,3.58,3.58,0,0,1,2.531-.9,3.668,3.668,0,0,1,2.531.868,2.783,2.783,0,0,1,.976,2.17,3.071,3.071,0,0,1-.976,2.332,3.537,3.537,0,0,1-2.531.922A3.577,3.577,0,0,1,1010.373,411.941Zm-.289,3.048h5.641v16.878h-5.641Z"
                                                transform="translate(-983.902 -406.553)" />
                                            <path fill="#ffffff"
                                                d="M1100.574,463.929v4.34h-17.358v-3.4l9.909-11.717h-9.656v-4.34h16.78v3.4l-9.909,11.717Z"
                                                transform="translate(-1047.488 -442.954)" />
                                            <path fill="#ffffff"
                                                d="M1242.651,458.334h-14.719a4.58,4.58,0,0,0,1.881,2.857,6.225,6.225,0,0,0,3.689,1.049,7.459,7.459,0,0,0,2.694-.452,6.529,6.529,0,0,0,2.188-1.428l3,3.255q-2.749,3.146-8.028,3.146a12.694,12.694,0,0,1-5.822-1.284,9.406,9.406,0,0,1-3.906-3.562,9.809,9.809,0,0,1-1.374-5.171,9.943,9.943,0,0,1,1.356-5.153,9.5,9.5,0,0,1,3.725-3.58,11.533,11.533,0,0,1,10.469-.054,8.983,8.983,0,0,1,3.634,3.526,10.518,10.518,0,0,1,1.32,5.334Q1242.76,456.924,1242.651,458.334Zm-13.2-6.256a4.618,4.618,0,0,0-1.591,2.965h9.583a4.667,4.667,0,0,0-1.591-2.947,4.758,4.758,0,0,0-3.182-1.1A4.858,4.858,0,0,0,1229.451,452.078Z"
                                                transform="translate(-1167.251 -441.157)" />
                                            <path fill="#ffffff"
                                                d="M1407.25,458.334h-14.719a4.58,4.58,0,0,0,1.881,2.857,6.225,6.225,0,0,0,3.689,1.049,7.459,7.459,0,0,0,2.694-.452,6.53,6.53,0,0,0,2.188-1.428l3,3.255q-2.749,3.146-8.028,3.146a12.693,12.693,0,0,1-5.822-1.284,9.4,9.4,0,0,1-3.906-3.562,9.809,9.809,0,0,1-1.374-5.171,9.943,9.943,0,0,1,1.356-5.153,9.5,9.5,0,0,1,3.725-3.58,11.533,11.533,0,0,1,10.469-.054,8.983,8.983,0,0,1,3.634,3.526,10.518,10.518,0,0,1,1.32,5.334Q1407.359,456.924,1407.25,458.334Zm-13.2-6.256a4.618,4.618,0,0,0-1.591,2.965h9.583a4.667,4.667,0,0,0-1.591-2.947,4.759,4.759,0,0,0-3.182-1.1A4.858,4.858,0,0,0,1394.051,452.078Z"
                                                transform="translate(-1309.031 -441.157)" />
                                        </g>
                                        <g transform="translate(445.797 439.343)">
                                            <path fill="#ffffff"
                                                d="M826.554,644.174h-1.4v-.664h3.588v.664h-1.4v3.564h-.785Z"
                                                transform="translate(-825.153 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M874.312,643.51v4.228h-.785v-1.812H871.34v1.812h-.785V643.51h.785v1.746h2.187V643.51Z"
                                                transform="translate(-864.261 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M924.272,647.08v.658H921.1V643.51h3.087v.659h-2.3v1.1h2.042v.646h-2.042v1.166Z"
                                                transform="translate(-907.798 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M995.518,643.691a1.394,1.394,0,0,1,.625.52,1.587,1.587,0,0,1,0,1.6,1.386,1.386,0,0,1-.625.522,2.338,2.338,0,0,1-.957.181h-.955v1.22h-.785V643.51h1.74A2.343,2.343,0,0,1,995.518,643.691Zm-.214,1.945a.861.861,0,0,0,0-1.245,1.206,1.206,0,0,0-.779-.218h-.918v1.679h.918A1.208,1.208,0,0,0,995.3,645.636Z"
                                                transform="translate(-969.577 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M1042.442,647.08v.658h-3.172V643.51h3.087v.659h-2.3v1.1h2.042v.646h-2.042v1.166Z"
                                                transform="translate(-1009.586 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M1086.481,647.738l-.864-1.238q-.055.006-.163.006h-.954v1.232h-.785V643.51h1.74a2.345,2.345,0,0,1,.957.181,1.393,1.393,0,0,1,.625.52,1.452,1.452,0,0,1,.218.8,1.434,1.434,0,0,1-.233.822,1.386,1.386,0,0,1-.667.513l.973,1.389Zm-.284-3.346a1.206,1.206,0,0,0-.779-.218h-.918v1.686h.918a1.2,1.2,0,0,0,.779-.221.767.767,0,0,0,.266-.625A.758.758,0,0,0,1086.2,644.392Z"
                                                transform="translate(-1047.869 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M1131.6,644.169v1.286h2.042v.665H1131.6v1.619h-.785V643.51h3.087v.659Z"
                                                transform="translate(-1088.442 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M1176.994,647.08v.658h-3.172V643.51h3.087v.659h-2.3v1.1h2.042v.646h-2.042v1.166Z"
                                                transform="translate(-1125.485 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M1217.059,647.142a2.071,2.071,0,0,1-.809-.776,2.266,2.266,0,0,1,0-2.235,2.092,2.092,0,0,1,.812-.776,2.381,2.381,0,0,1,1.157-.281,2.418,2.418,0,0,1,.949.181,1.94,1.94,0,0,1,.725.526l-.507.477a1.481,1.481,0,0,0-1.13-.5,1.584,1.584,0,0,0-.779.19,1.372,1.372,0,0,0-.538.528,1.62,1.62,0,0,0,0,1.535,1.371,1.371,0,0,0,.538.528,1.584,1.584,0,0,0,.779.19,1.472,1.472,0,0,0,1.13-.5l.507.483a1.927,1.927,0,0,1-.728.525,2.432,2.432,0,0,1-.951.181A2.382,2.382,0,0,1,1217.059,647.142Z"
                                                transform="translate(-1161.778 -643.074)" />
                                            <path fill="#ffffff" d="M1262.2,644.174h-1.4v-.664h3.588v.664h-1.4v3.564h-.785Z"
                                                transform="translate(-1200.4 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M1334.483,645.2h.743v1.685a2.362,2.362,0,0,1-.761.4,2.927,2.927,0,0,1-.894.139,2.411,2.411,0,0,1-1.166-.281,2.085,2.085,0,0,1-.815-.776,2.257,2.257,0,0,1,0-2.235,2.08,2.08,0,0,1,.818-.776,2.441,2.441,0,0,1,1.175-.281,2.568,2.568,0,0,1,.967.175,1.9,1.9,0,0,1,.731.513l-.5.483a1.589,1.589,0,0,0-1.166-.483,1.647,1.647,0,0,0-.794.187,1.362,1.362,0,0,0-.543.525,1.511,1.511,0,0,0-.2.773,1.487,1.487,0,0,0,.2.761,1.4,1.4,0,0,0,.543.532,1.594,1.594,0,0,0,.788.193,1.7,1.7,0,0,0,.87-.218Z"
                                                transform="translate(-1261.126 -643.074)" />
                                            <path fill="#ffffff"
                                                d="M1382.59,647.306a1.915,1.915,0,0,1-.489-1.411V643.51h.785v2.356q0,1.245,1.075,1.245t1.069-1.245V643.51h.773V645.9a1.923,1.923,0,0,1-.486,1.411,2.134,2.134,0,0,1-2.727,0Z"
                                                transform="translate(-1304.889 -643.45)" />
                                            <path fill="#ffffff" d="M1431.991,643.51h.785v4.228h-.785Z"
                                                transform="translate(-1347.863 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M1461.141,643.51h1.848a2.652,2.652,0,0,1,1.2.263,1.946,1.946,0,0,1,.816.743,2.265,2.265,0,0,1,0,2.217,1.945,1.945,0,0,1-.816.743,2.652,2.652,0,0,1-1.2.263h-1.848Zm1.812,3.564a1.8,1.8,0,0,0,.818-.178,1.276,1.276,0,0,0,.544-.508,1.63,1.63,0,0,0,0-1.528,1.277,1.277,0,0,0-.544-.508,1.794,1.794,0,0,0-.818-.178h-1.027v2.9Z"
                                                transform="translate(-1372.971 -643.45)" />
                                            <path fill="#ffffff"
                                                d="M1515.553,647.08v.658h-3.172V643.51h3.087v.659h-2.3v1.1h2.042v.646h-2.042v1.166Z"
                                                transform="translate(-1417.109 -643.45)" />
                                        </g>
                                        <g transform="translate(384.741 406.948)">
                                            <path fill="#ffffff"
                                                d="M384.741,446.083,400.6,418.829h-6.84l5.6-9.431h12.954l20.105,36.684H421.843L408.578,423.6l-12.851,22.486Z"
                                                transform="translate(-384.741 -409.399)" />
                                            <path fill="#f9c52d" d="M601.982,409.4h21.872l-10.936,19.118Z"
                                                transform="translate(-571.865 -409.399)" />
                                        </g>
                                    </g>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97 69" width="40" className="small_logo" style={{ marginLeft: -6 }}>
                                    <g id="Layer">
                                        <path fill="#ffffff" d="M0.29 68.12L29.73 17.51L17.03 17.51L27.42 0L51.48 0L88.82 68.13L69.19 68.13L44.55 26.37L20.69 68.13L0.29 68.12Z" />
                                        <path fill="#f9c52d" d="M56.22 0L96.83 0L76.53 35.5L56.22 0Z" />
                                    </g>
                                </svg>
                            </>
                        )
                    }
                </div>
                <Scrollbars style={{ height: '80vh' }}
                    renderThumbVertical={renderThumb}
                    autoHideTimeout={500}
                    autoHideDuration={200}>
                    {
                        Cookies.get("student_userlevel") != "1" && (Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 || Cookies.get("examid") == 9 || Cookies.get("examid") == 10 || Cookies.get("examid") == 11) ? (


                            partnerAccess == false ? (
                                <Nav className="navbar-nav menu_eamcet">
                                    <Accordion defaultActiveKey={this.getAccordianActive()}>
                                        <Nav.Item>
                                            <Link className={this.getActiveClass("/student/home")} to="/student/home" onClick={() => this.props.onClick()}>
                                                <Image src={HomeEamcet} className="ml-2" alt="New" width="15" height="15" /> <span>Home</span>
                                            </Link>
                                        </Nav.Item>
                                        {
                                            Cookies.get("student_userlevel") != "1" ?
                                                (
                                                    this.props.isuserValid.rizee_partnership == true ?
                                                        ("")
                                                        :
                                                        (
                                                            <Nav.Item>
                                                                <Link className={this.getActiveClass("/student/Rizee-partner-offers")} to="/student/Rizee-partner-offers" >
                                                                    <Image src={partnerOffers} className="ml-2" alt="New" width="15" height="15" /> <span>Partner Offers</span>
                                                                </Link>
                                                            </Nav.Item>
                                                        )
                                                )
                                                :
                                                ("")
                                        }
                                        {/* {
                                            Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ?
                                                (
                                                    Cookies.get("student_userlevel") != "1" ? (
                                                        this.props.isuserValid.EAMCET_LongTerm == true ? ("") : (
                                                            <Nav.Item>
                                                                <Link className={this.getActiveClass("/student/eamcet-longterm-course/home")} to="/student/eamcet-longterm-course/home" onClick={() => this.props.onClick()}>
                                                                    <Image src={longtermCourse} className="ml-2" alt="New" width="15" height="15" /> <span>Long Term Course <Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                                                </Link>
                                                            </Nav.Item>

                                                        )
                                                    ) : (
                                                        this.props.isuserValid.EAMCET_LongTerm == true ? ("") : (

                                                            <Nav.Item>
                                                                <Link className={this.getActiveClass("/student/eamcet-longterm-course/home")} to="/student/eamcet-longterm-course/home" onClick={() => this.props.onClick()}>
                                                                    <Image src={longtermCourse} className="ml-2" alt="New" width="15" height="15" /> <span>Long Term Course <Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                                                </Link>
                                                            </Nav.Item>

                                                        )
                                                    )
                                                )
                                                :
                                                ("")
                                        } */}


                                        {
                                            Cookies.get("toggle") == "wrapper sidebar-enable" && Cookies.get("student_userlevel") == 2 && (Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8) ?
                                                (<h6 className='nav_type_header'>ADMISSION</h6>)
                                                :
                                                ("")
                                        }
                                        {
                                            Cookies.get("student_userlevel") != "1" ? (
                                                Cookies.get("examid") == 3 || Cookies.get("examid") == 6 ?
                                                    (
                                                        <Nav.Item>
                                                            <Link className={this.getActiveClass("/student/college-rank-predictor")} to="/student/college-rank-predictor" onClick={() => this.props.onClick()}>
                                                                <Image src={Quiz} className="ml-1" alt="New" width="15" height="15" /> <span>College & Branch Predictor<Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                                            </Link>
                                                        </Nav.Item>
                                                    ) :
                                                    ("")

                                            ) : (
                                                this.props.isuserValid.college_branch_predi == true ? ("") : (

                                                    <Nav.Item>
                                                        <Link className={this.getActiveClass("/student/college-rank-predictor")} to="/student/college-rank-predictor" onClick={() => this.props.onClick()}>
                                                            <Image src={Quiz} className="ml-1" alt="New" width="15" height="15" /> <span>College & Branch Predictor<Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                                        </Link>
                                                    </Nav.Item>
                                                )
                                            )
                                        }


                                        {
                                            Cookies.get("examid") == "3" || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ?
                                                (
                                                    Cookies.get("student_userlevel") != "1" ? (
                                                        this.props.isuserValid.ST_RankPredictor == true ? ("") : (
                                                            <Nav.Item>
                                                                <Link className={this.getActiveClass("/student/eamcet-rankPredictor")} to="/student/eamcet-rankPredictor" onClick={() => this.props.onClick()}>
                                                                    <Image src={RankPredictor} className="ml-2" alt="New" width="15" height="15" /><span>Rank Predictor</span>
                                                                </Link>
                                                            </Nav.Item>
                                                        )
                                                    ) : (
                                                        this.props.isuserValid.ST_RankPredictor == true ? ("") : (

                                                            <Nav.Item>
                                                                <Link className={this.getActiveClass("/student/eamcet-rankPredictor")} to="/student/eamcet-rankPredictor" onClick={() => this.props.onClick()}>
                                                                    <Image src={RankPredictor} className="ml-2" alt="New" width="15" height="15" /><span>Rank Predictor</span>
                                                                </Link>
                                                            </Nav.Item>
                                                        )
                                                    )
                                                )
                                                :
                                                ("")
                                        }



                                        {
                                            Cookies.get("toggle") == "wrapper sidebar-enable" && Cookies.get("student_userlevel") == 2 && (Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8) ?
                                                (<h6 className='nav_type_header'>FOUNDATION</h6>)
                                                :
                                                ("")
                                        }
                                        {
                                            Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ?
                                                (
                                                    Cookies.get("student_userlevel") != "1" ? (
                                                        this.props.isuserValid.FoundationCourse == true ? ("") : (
                                                            <Nav.Item>
                                                                <Link className={this.getActiveClass("/student/foundation-course/home")} to="/student/foundation-course/home" onClick={() => this.props.onClick()}>
                                                                    <Image src={foundationNavIcon} className="ml-2" alt="New" width="12" height="17" /><span>Foundation Course <Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                                                </Link>
                                                            </Nav.Item>
                                                        )
                                                    ) : (
                                                        this.props.isuserValid.FoundationCourse == true ? ("") : (

                                                            <Nav.Item>
                                                                <Link className={this.getActiveClass("/student/foundation-course/home")} to="/student/foundation-course/home" onClick={() => this.props.onClick()}>
                                                                    <Image src={foundationNavIcon} className="ml-2" alt="New" width="12" height="17" /><span>Foundation Course <Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                                                </Link>
                                                            </Nav.Item>
                                                        )
                                                    )
                                                )
                                                :
                                                ("")


                                        }

                                        {
                                            Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ?
                                                (
                                                    Cookies.get("student_userlevel") != "1" ? (
                                                        this.props.isuserValid.Free_FoundationTests == true ? ("") : (
                                                            <Nav.Item>
                                                                <Link className={this.getActiveClass("/student/ready-to-foundation-tests")} to="/student/ready-to-foundation-tests" onClick={() => this.props.onClick()}>
                                                                    <Image src={foundationtest} className="ml-2" alt="New" width="15" height="15" /><span>Free Foundation Tests <Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                                                </Link>
                                                            </Nav.Item>

                                                        )
                                                    ) : (
                                                        this.props.isuserValid.Free_FoundationTests == true ? ("") : (

                                                            <Nav.Item>
                                                                <Link className={this.getActiveClass("/student/ready-to-foundation-tests")} to="/student/ready-to-foundation-tests" onClick={() => this.props.onClick()}>
                                                                    <Image src={foundationtest} className="ml-2" alt="New" width="15" height="15" /><span>Free Foundation Tests <Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                                                </Link>
                                                            </Nav.Item>

                                                        )
                                                    )
                                                )
                                                :
                                                ("")
                                        }
                                        {Cookies.get("examid") == 9 || Cookies.get("examid") == 10 || Cookies.get("examid") == 11 ? (<Nav.Item>
                                            <Link className={this.getActiveClass("/student/short-notes")} to="/student/short-notes">
                                                <Image src={ShortNotes} className="ml-2" alt="New" width="15" height="15" /> <span>Short Notes</span>
                                            </Link>
                                        </Nav.Item>) : ("")}

                                        {
                                            Cookies.get("examid") == 9 || Cookies.get("examid") == 10 || Cookies.get("examid") == 11 ?
                                                (
                                                    this.props.isuserValid.ins_revision_material_tab == true ? ("") : (<Nav.Item>
                                                        <Link className={this.getActiveClass("/student/revision-material-groups")} to="/student/revision-material-groups">
                                                            <i className="fal fa-books" title="Revision Materials" /> <span>Revision Material</span>
                                                        </Link>
                                                    </Nav.Item>)
                                                )
                                                :
                                                ("")
                                        }


                                        {
                                            Cookies.get("student_userlevel") == 1 ? (
                                                this.props.isuserValid.Custom_Materials == true ? ("") : (<Nav.Item>
                                                    <Link className={this.getActiveClass("/student/institute-revison-materials")} to="/student/institute-revison-materials">
                                                        <i className="fal fa-books" title="Revision Materials" /> <span>Custom Revision Material</span>
                                                    </Link>
                                                </Nav.Item>)
                                            ) : ("")
                                        }


                                        {
                                            Cookies.get("toggle") == "wrapper sidebar-enable" && Cookies.get("student_userlevel") == 2 && (Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8) ?
                                                (<h6 className='nav_type_header'>TEST PREP BUNDLE</h6>)
                                                :
                                                ("")
                                        }
                                        {
                                            Cookies.get("student_userlevel") != "1" ? (
                                                this.props.isuserValid.ST_PreviousTests == true ? ("") : (
                                                    <Nav.Item>
                                                        <Link className={this.getActiveClass("/student/StatePreviousPapers")} to="/student/StatePreviousPapers" onClick={() => this.props.onClick()}>
                                                            <Image src={EamcetPrevious} className="ml-2" alt="New" width="15" height="15" /> <span>Previous Tests</span>
                                                        </Link>
                                                    </Nav.Item>
                                                )
                                            ) : (
                                                this.props.isuserValid.ST_PreviousTests == true ? ("") : (

                                                    <Nav.Item>
                                                        <Link className={this.getActiveClass("/student/StatePreviousPapers")} to="/student/StatePreviousPapers" onClick={() => this.props.onClick()}>
                                                            <Image src={EamcetPrevious} className="ml-2" alt="New" width="15" height="15" /> <span>Previous Tests</span>
                                                        </Link>
                                                    </Nav.Item>)
                                            )}
                                        {
                                            Cookies.get("student_userlevel") != "1" ? (
                                                this.props.isuserValid.ST_MockTests == true ? ("") : (
                                                    <Nav.Item>

                                                        <Link className={this.getActiveClass("/student/Mocktests")} to="/student/Mocktests" onClick={() => this.props.onClick()}>
                                                            <Image src={MockTest} className="ml-2" alt="New" width="15" height="15" /> <span>Mock Tests</span>
                                                        </Link>

                                                    </Nav.Item>
                                                )
                                            ) : (
                                                this.props.isuserValid.ST_MockTests == true ? ("") : (

                                                    <Nav.Item>

                                                        <Link className={this.getActiveClass("/student/Mocktests")} to="/student/Mocktests" onClick={() => this.props.onClick()}>
                                                            <Image src={MockTest} className="ml-2" alt="New" width="15" height="15" /> <span>Mock Tests</span>
                                                        </Link>

                                                    </Nav.Item>
                                                )
                                            )}
                                        {
                                            Cookies.get("student_userlevel") != "1" ? (
                                                this.props.isuserValid.ST_Practice == true ? ("") : (
                                                    <Nav.Item>
                                                        <Link className={this.getActiveClass("/student/practise_test")} to="/student/practise_test" onClick={() => this.props.onClick()}>
                                                            <Image src={PractiseTest} className="ml-2" alt="New" width="15" height="15" /> <span>Practice Sessions</span>
                                                        </Link>
                                                    </Nav.Item>
                                                )
                                            ) : (
                                                this.props.isuserValid.ST_Practice == true ? ("") : (
                                                    <Nav.Item>
                                                        <Link className={this.getActiveClass("/student/practise_test")} to="/student/practise_test" onClick={() => this.props.onClick()}>
                                                            <Image src={PractiseTest} className="ml-2" alt="New" width="15" height="15" /> <span>Practice Sessions</span>
                                                        </Link>
                                                    </Nav.Item>
                                                )
                                            )
                                        }
                                        {
                                            Cookies.get("student_userlevel") != "1" ?
                                                (
                                                    this.props.isuserValid.ST_Error_Exam == true ? ("") :
                                                        (
                                                            <Nav.Item>
                                                                <Link className={this.getActiveClass("/student/error_exam")} to="/student/error_exam" onClick={() => this.props.onClick()} >
                                                                    <Image src={CrashCourse} className="ml-2" alt="New" width="15" height="15" /><span>Error Exam <Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                                                </Link>
                                                            </Nav.Item>
                                                        )
                                                )
                                                :
                                                (
                                                    this.props.isuserValid.ST_Error_Exam == true ? ("") :
                                                        (
                                                            <Nav.Item>
                                                                <Link className={this.getActiveClass("/student/error_exam")} to="/student/error_exam" onClick={() => this.props.onClick()} >
                                                                    <Image src={CrashCourse} className="ml-2" alt="New" width="15" height="15" /><span>Error Exam <Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                                                </Link>
                                                            </Nav.Item>
                                                        )
                                                )
                                        }

                                        {
                                            Cookies.get("student_userlevel") != "1" ?
                                                (
                                                    this.props.isuserValid.ST_Performance == true ? ("") :
                                                        (
                                                            <Nav.Item>
                                                                <Link className={this.getActiveClass("/student/performance-analysis")} to="/student/performance-analysis" onClick={() => this.props.onClick()} >
                                                                    <Image src={CrashCourse} className="ml-2" alt="New" width="15" height="15" /><span>Performace Analysis <Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                                                </Link>
                                                            </Nav.Item>
                                                        )
                                                )
                                                :
                                                (
                                                    this.props.isuserValid.ST_Performance == true ? ("") :
                                                        (
                                                            <Nav.Item>
                                                                <Link className={this.getActiveClass("/student/performance-analysis")} to="/student/performance-analysis" onClick={() => this.props.onClick()} >
                                                                    <Image src={CrashCourse} className="ml-2" alt="New" width="15" height="15" /><span>Performace Analysis <Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                                                </Link>
                                                            </Nav.Item>
                                                        )
                                                )
                                        }

                                        {
                                            Cookies.get("toggle") == "wrapper sidebar-enable" && Cookies.get("student_userlevel") == 2 && (Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8) ?
                                                (<h6 className='nav_type_header'>PREPARATION</h6>)
                                                :
                                                ("")
                                        }
                                        <React.Fragment>
                                            <Accordion.Toggle as={Nav.Item} eventKey={2} className="HasChild">
                                                <a className={this.getActiveClass("/student/chapter-learning")}
                                                >
                                                    <i className="fal fa-cogs" title="Learning Tools" /> <span>Chapter wise Learning</span>
                                                </a>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={2}>
                                                <div className="collapse-inner">
                                                    {
                                                        Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ?
                                                            (
                                                                Cookies.get("student_userlevel") != "1" ?
                                                                    (
                                                                        this.props.isuserValid.Chapterpreviousquestions == true ? ("")
                                                                            :
                                                                            (
                                                                                <Nav.Item>
                                                                                    <Link className={this.getActiveClassTwo("/student/Chapter-wise-questions")} to="/student/Chapter-wise-questions" >
                                                                                        <Image src={PractiseTest} className="ml-2" alt="New" width="15" height="15" /> <span>Previous Paper Questions</span>
                                                                                    </Link>
                                                                                </Nav.Item>
                                                                            )
                                                                    )
                                                                    :
                                                                    (
                                                                        this.props.isuserValid.Chapterpreviousquestions == true ? ("") : (
                                                                            <Nav.Item>
                                                                                <Link className={this.getActiveClassTwo("/student/Chapter-wise-questions")} to="/student/Chapter-wise-questions" >
                                                                                    <Image src={PractiseTest} className="ml-2" alt="New" width="15" height="15" /> <span>Previous Paper Questions</span>
                                                                                </Link>
                                                                            </Nav.Item>
                                                                        )
                                                                    )
                                                            )
                                                            :
                                                            ("")
                                                    }

                                                    {
                                                        Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ?
                                                            (
                                                                Cookies.get("student_userlevel") != "1" ? (
                                                                    this.props.isuserValid.ST_ShortNotes == true ? ("") : (
                                                                        <Nav.Item>
                                                                            <Link className={this.getActiveClassTwo("/student/StateShortNotes")} to="/student/StateShortNotes" onClick={() => this.props.onClick()}>
                                                                                <Image src={ShortNotes} className="ml-2" alt="New" width="15" height="15" /> <span>Short Notes</span>
                                                                            </Link>
                                                                        </Nav.Item>
                                                                    )
                                                                ) : (
                                                                    this.props.isuserValid.ST_ShortNotes == true ? ("") : (
                                                                        <Nav.Item>
                                                                            <Link className={this.getActiveClassTwo("/student/StateShortNotes")} to="/student/StateShortNotes" onClick={() => this.props.onClick()}>
                                                                                <Image src={ShortNotes} className="ml-2" alt="New" width="15" height="15" /> <span>Short Notes</span>
                                                                            </Link>
                                                                        </Nav.Item>
                                                                    )
                                                                )
                                                            )
                                                            :
                                                            ("")
                                                    }

                                                    {
                                                        Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ?
                                                            (
                                                                Cookies.get("student_userlevel") != "1" ? (
                                                                    this.props.isuserValid.ST_RevisionMaterial == true ? ("") : (
                                                                        <Nav.Item>
                                                                            <Link className={this.getActiveClassTwo("/student/FormulaeSheets")} to="/student/FormulaeSheets" onClick={() => this.props.onClick()}>
                                                                                <Image src={Formulas} className="ml-2" alt="New" width="15" height="15" /> <span>Revision Material</span>
                                                                            </Link>
                                                                        </Nav.Item>
                                                                    )
                                                                ) : (
                                                                    this.props.isuserValid.ST_RevisionMaterial == true ? ("") : (
                                                                        <Nav.Item>
                                                                            <Link className={this.getActiveClassTwo("/student/FormulaeSheets")} to="/student/FormulaeSheets" onClick={() => this.props.onClick()}>
                                                                                <Image src={Formulas} className="ml-2" alt="New" width="15" height="15" /> <span>Revision Material</span>
                                                                            </Link>
                                                                        </Nav.Item>
                                                                    )
                                                                )
                                                            )
                                                            :
                                                            ("")
                                                    }
                                                    {
                                                        Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ?
                                                            (
                                                                Cookies.get("student_userlevel") != "1" ? (
                                                                    this.props.isuserValid.ChapterVideos == true ? ("") : (
                                                                        <Nav.Item>
                                                                            <Link className={this.getActiveClassTwo("/student/Eamcet-Video-classes")} to="/student/Eamcet-Video-classes" >
                                                                                <i className="fal fa-video" title="Videos" /> <span>Video Classes</span>
                                                                            </Link>
                                                                        </Nav.Item>
                                                                    )
                                                                ) : (
                                                                    this.props.isuserValid.ChapterVideos == true ? ("") : (
                                                                        <Nav.Item>
                                                                            <Link className={this.getActiveClassTwo("/student/Eamcet-Video-classes")} to="/student/Eamcet-Video-classes" >
                                                                                <i className="fal fa-video" title="Videos" /> <span>Video Classes</span>
                                                                            </Link>
                                                                        </Nav.Item>
                                                                    )
                                                                )
                                                            )
                                                            :
                                                            ("")
                                                    }
                                                    {
                                                        Cookies.get("student_userlevel") != "1" ? (
                                                            this.props.isuserValid.ST_BluePrint == true ? ("") : (
                                                                <Nav.Item>
                                                                    <Link className={this.getActiveClassTwo("/student/StateBluePrint")} to="/student/StateBluePrint" onClick={() => this.props.onClick()}>
                                                                        <Image src={BluePrint} className="ml-2" alt="New" width="15" height="15" /> <span>Blue Print</span>
                                                                    </Link>
                                                                </Nav.Item>
                                                            )
                                                        ) : (
                                                            this.props.isuserValid.ST_BluePrint == true ? ("") : (

                                                                <Nav.Item>
                                                                    <Link className={this.getActiveClassTwo("/student/StateBluePrint")} to="/student/StateBluePrint" onClick={() => this.props.onClick()}>
                                                                        <Image src={BluePrint} className="ml-2" alt="New" width="15" height="15" /> <span>Blue Print</span>
                                                                    </Link>
                                                                </Nav.Item>
                                                            )
                                                        )
                                                    }
                                                </div>
                                            </Accordion.Collapse>
                                        </React.Fragment>
                                        {
                                            Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ?
                                                (
                                                    Cookies.get("student_userlevel") != "1" ? (
                                                        this.props.isuserValid.IPEpapers == true ?
                                                            ("")
                                                            :
                                                            (
                                                                <Nav.Item>
                                                                    <Link className={this.getActiveClass("/student/Ipe-question-papers")} to="/student/Ipe-question-papers" >
                                                                        <Image src={EamcetPrevious} className="ml-2" alt="New" width="15" height="15" /> <span>IPE Previous Papers <Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                                                    </Link>
                                                                </Nav.Item>

                                                            )
                                                    ) : (
                                                        this.props.isuserValid.IPEpapers == true ? ("") : (

                                                            <Nav.Item>
                                                                <Link className={this.getActiveClass("/student/Ipe-question-papers")} to="/student/Ipe-question-papers" >
                                                                    <Image src={EamcetPrevious} className="ml-2" alt="New" width="15" height="15" /> <span>IPE Previous Papers <Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                                                </Link>
                                                            </Nav.Item>

                                                        )
                                                    )
                                                )
                                                :
                                                ("")
                                        }


                                        {
                                            Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8 ?
                                                (
                                                    Cookies.get("student_userlevel") == "1" ? (
                                                        this.props.isuserValid.ST_IPELectures2022 == true ? ("") : (
                                                            <Nav.Item>
                                                                <Link className={this.getActiveClass("/student/IpeClasses")} to="/student/IpeClasses" onClick={() => this.props.onClick()}>
                                                                    <Image src={IpeLectures} className="ml-2" alt="New" width="15" height="15" /> <span>IPE Lectures</span>
                                                                </Link>
                                                            </Nav.Item>
                                                        )

                                                    ) : (
                                                        this.props.isuserValid.ST_IPELectures2022 == true ? ("") : (
                                                            <Nav.Item>
                                                                <Link className={this.getActiveClass("/student/IpeClasses")} to="/student/IpeClasses" onClick={() => this.props.onClick()}>
                                                                    <Image src={IpeLectures} className="ml-2" alt="New" width="15" height="15" /> <span>IPE Lectures</span>
                                                                </Link>
                                                            </Nav.Item>
                                                        )
                                                    )
                                                )
                                                :
                                                ("")

                                        }
                                        {
                                            Cookies.get("student_userlevel") != "1" ? (
                                                this.props.isuserValid.ST_Quiz == true ? ("") : (
                                                    <Nav.Item>
                                                        <Link className={this.getActiveClass("/student/exams/quiz_exam")} to="/student/exams/quiz_exam" onClick={() => this.props.onClick()}>
                                                            <Image src={Quiz} className="ml-2" alt="New" width="15" height="15" /> <span>Quiz</span>
                                                        </Link>
                                                    </Nav.Item>
                                                )
                                            ) : (
                                                this.props.isuserValid.ST_Quiz == true ? ("") : (

                                                    <Nav.Item>
                                                        <Link className={this.getActiveClass("/student/exams/quiz_exam")} to="/student/exams/quiz_exam" onClick={() => this.props.onClick()}>
                                                            <Image src={Quiz} className="ml-2" alt="New" width="15" height="15" /> <span>Quiz</span>
                                                        </Link>
                                                    </Nav.Item>
                                                )
                                            )}


                                        {
                                            Cookies.get("toggle") == "wrapper sidebar-enable" && Cookies.get("student_userlevel") == 2 && (Cookies.get("examid") == 3 || Cookies.get("examid") == 6 || Cookies.get("examid") == 7 || Cookies.get("examid") == 8) ?
                                                (<h6 className='nav_type_header'>OTHERS</h6>)
                                                :
                                                ("")
                                        }
                                        {
                                            Cookies.get("student_userlevel") != "1" ? (
                                                <Nav.Item>
                                                    <Link className={this.getActiveClass("/student/plans_section")} to="/student/plans_section" onClick={() => this.props.onClick()}>
                                                        <Image src={Subscriptions} className="ml-2" alt="New" width="15" height="15" /><span>Subscription Plans</span>
                                                    </Link>
                                                </Nav.Item>
                                            ) : ("")

                                        }
                                        {
                                            Cookies.get("student_userlevel") != "1" ?
                                                (
                                                    <Nav.Item>
                                                        <Link className={this.getActiveClass("/student/referFriend")} to="/student/referFriend" onClick={() => this.props.onClick()}>
                                                            <i className="fal fa-clipboard-list-check" title="Refer Friend" /> <span>Refer a Friend</span>
                                                        </Link>
                                                    </Nav.Item>
                                                )
                                                :
                                                ("")
                                        }
                                        {
                                            Cookies.get("student_userlevel") != "1" ? (
                                                this.props.isuserValid.ST_Blog == true ? ("") : (
                                                    <Nav.Item>
                                                        <Link className={this.getActiveClass("/student/student-blog")} to="/student/student-blog" onClick={() => this.props.onClick()}>
                                                            <Image src={BlogEamcet} className="ml-2" alt="New" width="15" height="15" /><span>Blog</span>
                                                        </Link>
                                                    </Nav.Item>
                                                )
                                            ) : (
                                                this.props.isuserValid.ST_Blog == true ? ("") : (
                                                    <Nav.Item>
                                                        <Link className={this.getActiveClass("/student/student-blog")} to="/student/student-blog" onClick={() => this.props.onClick()}>
                                                            <Image src={BlogEamcet} className="ml-2" alt="New" width="15" height="15" /><span>Blog</span>
                                                        </Link>
                                                    </Nav.Item>
                                                )
                                            )
                                        }

                                    </Accordion>
                                </Nav>
                            ) : (
                                <Nav className="navbar-nav menu_eamcet">
                                    <Accordion defaultActiveKey={this.getAccordianActive()}>

                                        {
                                            Cookies.get("toggle") == "wrapper sidebar-enable" && Cookies.get("student_userlevel") == 2 ?
                                                (<h6 className='nav_type_header'>TEST PREP BUNDLE</h6>)
                                                :
                                                ("")
                                        }

                                        <Nav.Item>
                                            <Link className={this.getActiveClass("/student/StatePreviousPapers")} to="/student/StatePreviousPapers" onClick={() => this.props.onClick()}>
                                                <Image src={EamcetPrevious} className="ml-2" alt="New" width="15" height="15" /> <span>Previous Tests</span>
                                            </Link>
                                        </Nav.Item>


                                        <Nav.Item>

                                            <Link className={this.getActiveClass("/student/Mocktests")} to="/student/Mocktests" onClick={() => this.props.onClick()}>
                                                <Image src={MockTest} className="ml-2" alt="New" width="15" height="15" /> <span>Mock Tests</span>
                                            </Link>

                                        </Nav.Item>

                                        <Nav.Item>
                                            <Link className={this.getActiveClass("/student/practise_test")} to="/student/practise_test" onClick={() => this.props.onClick()}>
                                                <Image src={PractiseTest} className="ml-2" alt="New" width="15" height="15" /> <span>Practice Sessions</span>
                                            </Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Link className={this.getActiveClass("/student/error_exam")} to="/student/error_exam" onClick={() => this.props.onClick()} >
                                                <Image src={CrashCourse} className="ml-2" alt="New" width="15" height="15" /><span>Error Exam <Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                            </Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Link className={this.getActiveClass("/student/performance-analysis")} to="/student/performance-analysis" onClick={() => this.props.onClick()} >
                                                <Image src={CrashCourse} className="ml-2" alt="New" width="15" height="15" /><span>Performace Analysis <Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                            </Link>
                                        </Nav.Item>

                                    </Accordion>
                                </Nav>

                            )

                        ) : (
                            partnerAccess == false ? (
                                <Nav className="navbar-nav menu">
                                    <Accordion defaultActiveKey={this.getAccordianActive()}>
                                        <Nav.Item>
                                            <Link className={this.getActiveClass("/student/home")} to="/student/home" onClick={() => this.props.onClick()}>
                                                <i className="fal fa-home" title="Home" /> <span>Home</span>
                                            </Link>
                                        </Nav.Item>
                                        {/* {
                                        Cookies.get("examid") == 1  ?
                                            (
                                                this.isStudentUserValid.current_plan_id == 25 || this.isStudentUserValid.current_plan_id == 28 || this.isStudentUserValid.current_plan_id == 31 || this.isStudentUserValid.current_plan_id == 29 ?
                                                    (
                                                        <Nav.Item>
                                                            <Link className={this.getActiveClass("/student/Neet_Rise_Course")} to="/student/Neet_Rise_Course">
                                                                <i className="fas fa-book-open" title="NEET Rise Course" /> {Cookies.get("examid") == 1 ? (<><span>NEET Rise Course</span> <span className="label-bg">New</span></>) : (<span>JEE Repeaters</span>)}
                                                            </Link>
                                                        </Nav.Item>
                                                    )
                                                    :
                                                    (
                                                        <Nav.Item>
                                                            <Link className={this.getActiveClass("/student/Neet_Crash_Course")} to="/student/Neet_Crash_Course">
                                                                <i className="fas fa-book-open" title="NEET Crash Course" /> {Cookies.get("examid") == 1 ? (<><span>NEET 2023 Crash Course</span> <span className="label-bg">New</span></>) : (<span>JEE Repeaters</span>)}
                                                            </Link>
                                                        </Nav.Item>
                                                    )

                                            )
                                            :
                                            ("")
                                    } */}

                                        {
                                            Cookies.get("student_userlevel") != "1" ?
                                                (
                                                    this.props.isuserValid.rizee_partnership == true ?
                                                        ("")
                                                        :
                                                        (
                                                            <Nav.Item>
                                                                <Link className={this.getActiveClass("/student/Rizee-partner-offers")} to="/student/Rizee-partner-offers" >
                                                                    <Image src={partnerOffers} className="ml-2" alt="New" width="15" height="15" /> <span>Partner Offers</span>
                                                                </Link>
                                                            </Nav.Item>
                                                        )
                                                )
                                                :
                                                ("")
                                        }
                                        {
                                            Cookies.get("student_userlevel") == 1 ?
                                                (
                                                    <Nav.Item>
                                                        <Link className={this.getActiveClass("/student/exams/schedule-exam")} to="/student/exams/schedule-exam" onClick={() => this.props.onClick()}>
                                                            <Image src={CrashCourse} className="ml-2" alt="New" width="15" height="15" /><span>Schedule Exam</span>
                                                        </Link>
                                                    </Nav.Item>
                                                )
                                                :
                                                ("")
                                        }

                                        {
                                            Cookies.get("toggle") == "wrapper sidebar-enable" && Cookies.get("student_userlevel") == 2 ?
                                                (<h6 className='nav_type_header'>TEST PREP BUNDLE</h6>)
                                                :
                                                ("")
                                        }
                                        {
                                            <Nav.Item>
                                                <Link className={this.getActiveClass("/student/previouspaperexams")} to="/student/previouspaperexams" onClick={() => this.props.onClick()}>
                                                    <i className="fal fa-clipboard-list-check" title="Previous papers" /> <span>Previous Year Papers</span>
                                                </Link>
                                            </Nav.Item>
                                        }
                                        {
                                            Cookies.get("examid") == 2 ? (
                                                this.props.isuserValid.ins_mock_test == true ? ("") : (<Nav.Item>

                                                    <Link className={this.getActiveClass("/student/exams/test-series")} to="/student/exams/test-series" onClick={() => this.props.onClick()}>
                                                        <i className="fal fa-badge-check" title="Exams History" /> <span>Mock Tests with Analysis</span>
                                                    </Link>

                                                </Nav.Item>)

                                            ) : ("")
                                        }
                                        {
                                            Cookies.get("examid") == 1 ? (
                                                this.props.isuserValid.userlevel == 1 ?
                                                    (
                                                        this.props.isuserValid.ins_mock_test_nta != true ?
                                                            (
                                                                <Nav.Item>
                                                                    <Link className={this.getActiveClass("/student/exams/test-series/NewNTAPattern")} to="/student/exams/test-series/NewNTAPattern" onClick={() => this.props.onClick()}>
                                                                        <i className="fal fa-clipboard-list-check" title="New NTA Pattern" /> <span>Mock Tests with Analysis</span>
                                                                    </Link>
                                                                </Nav.Item>
                                                            )
                                                            :
                                                            ("")
                                                    )
                                                    :
                                                    (
                                                        <Nav.Item>
                                                            <Link className={this.getActiveClass("/student/exams/test-series/NewNTAPattern")} to="/student/exams/test-series/NewNTAPattern" onClick={() => this.props.onClick()}>
                                                                <i className="fal fa-clipboard-list-check" title="New NTA Pattern" /> <span>Mock Tests with Analysis</span>
                                                            </Link>
                                                        </Nav.Item>
                                                    )
                                            ) : ("")

                                        }
                                        {this.props.isuserValid.error_exam == true ? ("") : (<Nav.Item>
                                            <Link className={this.getActiveClass("/student/exams/error-exam")} to="/student/exams/error-exam">
                                                <i className="fas fa-exclamation-circle" title="Error Exam" /> <span>Error Exam</span>
                                            </Link>
                                        </Nav.Item>)}

                                        {this.props.isuserValid.ins_analysis_tab == true ? ("") : (
                                            <Nav.Item>
                                                <Link className={this.getActiveClass("/student/result-analysis")} to="/student/result-analysis" onClick={() => this.props.onClick()}>
                                                    <i className="fal fa-analytics" title="Result &amp; Analysis" /><span>Performance Analysis</span>
                                                </Link>
                                            </Nav.Item>

                                        )}

                                        {
                                            Cookies.get("student_userlevel") == 1 ? (
                                                this.props.isuserValid.Custom_Materials == true ? ("") : (<Nav.Item>
                                                    <Link className={this.getActiveClass("/student/institute-revison-materials")} to="/student/institute-revison-materials">
                                                        <i className="fal fa-books" title="Revision Materials" /> <span>Custom Revision Material</span>
                                                    </Link>
                                                </Nav.Item>)
                                            ) : ("")
                                        }







                                        {/*this.props.isuserValid.ins_mock_test == true ? ("") : (<Nav.Item>

                                        <Link className={this.getActiveClass("/student/exams/test-series")} to="/student/exams/test-series" onClick={() => this.props.onClick()}>
                                            <i className="fal fa-badge-check" title="Exams History" /> <span>Mock Tests</span>
                                        </Link>

                                    </Nav.Item>)*/}

                                        {/* {
                                        Cookies.get("toggle") == "wrapper sidebar-enable" ?
                                            (<h6 className='nav_type_header'>FOUNDATION</h6>)
                                            :
                                            ("")
                                    }
                                    {
                                        Cookies.get("student_userlevel") != "1" ? (
                                            this.props.isuserValid.FoundationCourse == true ? ("") : (
                                                <Nav.Item>
                                                    <Link className={this.getActiveClass("/student/foundation-course/home")} to="/student/foundation-course/home" onClick={() => this.props.onClick()}>
                                                        <Image src={foundationNavIcon} className="ml-2" alt="New" width="12" height="17" /><span>Foundation Course <Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                                    </Link>
                                                </Nav.Item>
                                            )
                                        ) : (
                                            this.props.isuserValid.FoundationCourse == true ? ("") : (

                                                <Nav.Item>
                                                    <Link className={this.getActiveClass("/student/foundation-course/home")} to="/student/foundation-course/home" onClick={() => this.props.onClick()}>
                                                        <Image src={foundationNavIcon} className="ml-2" alt="New" width="12" height="17" /><span>Foundation Course <Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                                    </Link>
                                                </Nav.Item>
                                            )
                                        )
                                    }
                                    {
                                        Cookies.get("student_userlevel") != "1" ? (
                                            this.props.isuserValid.Free_FoundationTests == true ? ("") : (
                                                <Nav.Item>
                                                    <Link className={this.getActiveClass("/student/ready-to-foundation-tests")} to="/student/ready-to-foundation-tests" onClick={() => this.props.onClick()}>
                                                        <Image src={foundationtest} className="ml-2" alt="New" width="15" height="15" /><span>Free Foundation Tests <Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                                    </Link>
                                                </Nav.Item>

                                            )
                                        ) : (
                                            this.props.isuserValid.Free_FoundationTests == true ? ("") : (

                                                <Nav.Item>
                                                    <Link className={this.getActiveClass("/student/ready-to-foundation-tests")} to="/student/ready-to-foundation-tests" onClick={() => this.props.onClick()}>
                                                        <Image src={foundationtest} className="ml-2" alt="New" width="15" height="15" /><span>Free Foundation Tests <Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                                    </Link>
                                                </Nav.Item>

                                            )
                                        )
                                    } */}

                                        {
                                            Cookies.get("toggle") == "wrapper sidebar-enable" && Cookies.get("student_userlevel") == 2 ?
                                                (<h6 className='nav_type_header'>PREPARATION</h6>)
                                                :
                                                ("")
                                        }


                                        {this.props.isuserValid.ins_linkage_analysis_tab == true ? ("") : (
                                            <Nav.Item>
                                                <Link className={this.getActiveClass("/student/linkage-chapter-analysis")} to="/student/linkage-chapter-analysis" onClick={() => this.props.onClick()}>
                                                    <i className="fal fa-link" title="Linkage Chapter Analysis" /><span>Linkage Chapter Analysis</span>
                                                </Link>
                                            </Nav.Item>
                                        )}
                                        {this.props.isuserValid.ins_previous_paper_analysis_tab == true ? ("") : (<Nav.Item>
                                            <Link className={this.getActiveClass("/student/previous-paper-analysis")} to="/student/previous-paper-analysis" onClick={() => this.props.onClick()}>
                                                <i className="fal fa-gift-card" title="Previous Paper Analysis" /><span>Previous Paper Analysis</span>
                                            </Link>
                                        </Nav.Item>)}

                                        {this.props.isuserValid.ins_learn_tab == true ? ("") : (<Nav.Item>
                                            <Link className={this.getActiveClass("/student/learn-practice")} to="/student/learn-practice" onClick={() => this.props.onClick()}>
                                                <i className="fal fa-book-reader" title="Learn &amp; Practice" /><span>Learn &amp; Practise</span>
                                            </Link>
                                        </Nav.Item>)}
                                        {this.props.isuserValid.ins_videos_tab == true ? ("") : (<Nav.Item>
                                            <Link className={this.getActiveClass("/student/category_wise_videos")} to="/student/category_wise_videos" onClick={() => this.props.onClick()}>
                                                <i className="fal fa-video" title="Videos" /> <span>Watch & Learn<Image src={pulseImage} className="ml-2" alt="New" width="20" /></span>
                                            </Link>
                                        </Nav.Item>)}
                                        {this.props.isuserValid.ins_revision_material_tab == true ? ("") : (<Nav.Item>
                                            <Link className={this.getActiveClass("/student/revision-material-groups")} to="/student/revision-material-groups" onClick={() => this.props.onClick()}>
                                                <i className="fal fa-books" title="Revision Materials" /> <span>Revision Material</span>
                                            </Link>
                                        </Nav.Item>)}

                                        {this.props.isuserValid.ins_exam_tab == true ? ("") : (<Nav.Item>
                                            <Link className={this.getActiveClass("/student/exams/quiz_exam")} to="/student/exams/quiz_exam" onClick={() => this.props.onClick()}>
                                                <i className="fas fa-question" title="Quiz &amp; Assignment" /> <span>Quiz</span>
                                            </Link>
                                        </Nav.Item>)}



                                        {
                                            Cookies.get("toggle") == "wrapper sidebar-enable" && Cookies.get("student_userlevel") == 2 ?
                                                (<h6 className='nav_type_header'>SELF EXAMINAION</h6>)
                                                :
                                                ("")
                                        }



                                        {this.props.isuserValid.ins_exam_tab == true ? ("") : (<Nav.Item>
                                            <Link className={this.getActiveClass("/student/exams")} to="/student/exams" onClick={() => this.props.onClick()}>
                                                <i className="fal fa-clipboard-list-check" title="Create Exam" /> <span>Create Exam</span>
                                            </Link>
                                        </Nav.Item>)}


                                        {
                                            Cookies.get("examid") == "2" && Cookies.get("student_userlevel") == 2 ?
                                                (
                                                    <>
                                                        <h6 className='nav_type_header'>ADMISSION</h6>
                                                        <Nav.Item>
                                                            <Link className={this.getActiveClass("/student/NTA-College_predictor")} to="/student/NTA-College_predictor">
                                                                <Image src={Quiz} className="ml-1" alt="New" width="15" height="15" /> <span>NTA College Predictor<Image src={gifImage} className="ml-1" alt="New" width="35" /></span>
                                                            </Link>
                                                        </Nav.Item>
                                                    </>
                                                )
                                                :
                                                ("")
                                        }
                                        {
                                            Cookies.get("toggle") == "wrapper sidebar-enable" && Cookies.get("student_userlevel") == 2 ?
                                                (<h6 className='nav_type_header'>OTHERS</h6>)
                                                :
                                                ("")
                                        }
                                        {this.props.isuserValid.ins_package_tab == true ? ("") : (<React.Fragment>
                                            {Cookies.get("student_userlevel") != 1 ? (<Nav.Item>
                                                <Link className={this.getActiveClass("/student/package")} to="/student/package" onClick={() => this.props.onClick()}>
                                                    <i className="fal fa-sack" title="Package" /> <span>Subscription Plans</span>
                                                </Link>
                                            </Nav.Item>) : ("")}
                                        </React.Fragment>)}
                                        {
                                            Cookies.get("student_userlevel") != "1" ?
                                                (
                                                    <Nav.Item>
                                                        <Link className={this.getActiveClass("/student/referFriend")} to="/student/referFriend" onClick={() => this.props.onClick()}>
                                                            <i className="fal fa-clipboard-list-check" title="Refer Friend" /> <span>Refer a Friend</span>
                                                        </Link>
                                                    </Nav.Item>
                                                )
                                                :
                                                ("")
                                        }
                                        {this.props.isuserValid.ins_help_videos == true ? ("") : (<Nav.Item>
                                            <Link className={this.getActiveClass("/student/featuredvideos")} to="/student/featuredvideos" onClick={() => this.props.onClick()}>
                                                <i className="fal fa-video" title="Help Videos" /> <span>Help Videos</span>
                                            </Link>
                                        </Nav.Item>)}

                                        {this.props.isuserValid.ins_ready_exam_tab == false || this.props.isuserValid.ins_bookmarks_tab == false || this.props.isuserValid.ins_notes_tab == false ? (
                                            <React.Fragment>
                                                <Accordion.Toggle as={Nav.Item} eventKey={2} className="HasChild">
                                                    <a className="nav-link"
                                                    >
                                                        <i className="fal fa-cogs" title="Learning Tools" /> <span>Learning Tools</span>
                                                    </a>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={2}>
                                                    <div className="collapse-inner">
                                                        {this.props.isuserValid.ins_ready_exam_tab == true ? ("") : (<Nav.Item>
                                                            <Link className={this.getActiveClassTwo("/student/get-ready-for-exam")} to="/student/get-ready-for-exam" onClick={() => this.props.onClick()}>
                                                                <i className="fal fa-running" title="Get Ready For Exam" /> <span>Get Ready For Exam</span>
                                                            </Link>
                                                        </Nav.Item>)}
                                                        {this.props.isuserValid.ins_bookmarks_tab == true ? ("") : (<Nav.Item>
                                                            <Link className={this.getActiveClassTwo("/student/bookmark")} to="/student/bookmark" onClick={() => this.props.onClick()}>
                                                                <i className="fal fa-bookmark" title="Bookmarks" /> <span>Bookmarks</span>
                                                            </Link>
                                                        </Nav.Item>)}

                                                        {this.props.isuserValid.ins_notes_tab == true ? ("") : (<Nav.Item>
                                                            <Link className={this.getActiveClassTwo("/student/notes")} to="/student/notes" onClick={() => this.props.onClick()}>
                                                                <i className="fal fa-book" title="Notes" /> <span>Notes</span>
                                                            </Link>
                                                        </Nav.Item>)}

                                                    </div>
                                                </Accordion.Collapse>
                                            </React.Fragment>
                                        ) : ("")}
                                        {this.props.isuserValid.ins_blog == true || this.props.isuserValid.institution_id == "46" || this.props.isuserValid.institution_id == "50" || this.props.isuserValid.institution_id == "51" ? ("") : (
                                            <Nav.Item>
                                                <Link className={this.getActiveClass("/student/student-blog")} to="/student/student-blog" onClick={() => this.props.onClick()}>
                                                    <i className="fal fa-blog" /><span>Blog</span>
                                                </Link>
                                            </Nav.Item>
                                        )}
                                        {/*this.props.isuserValid.ins_videos_tab == true ? ("") : (<Nav.Item>
                                        <Link className={this.getActiveClass("/student/videos")} to="/student/videos">
                                            <i className="fal fa-video" title="Videos" /> <span>Live Classes<Image src={pulseImage} className="ml-2" alt="New" width="20" /></span>
                                        </Link>
                                    </Nav.Item>)*/}

                                        {/* {
                                        Cookies.get("examid") == 1 ?
                                            (
                                                <Nav.Item>
                                                    <Link className={this.getActiveClass("/student/live_Test_series/exams")} to="/student/live_Test_series/exams">
                                                        <i className="fal fa-clipboard-list-check" title="NEET Crash Course" /> {Cookies.get("examid") == 1 ? (<><span> Live Test Series</span> <span className="label-bg">New</span></>) : ("")}
                                                    </Link>
                                                </Nav.Item>
                                            )
                                            :
                                            ("")
                                    } */}



                                        {/* {
                                        Cookies.get("examid") == 2 && this.props.isuserValid.ins_jee_mains_2021 == false ? (
                                            <Nav.Item>
                                                <Link className={this.getActiveClass("/student/createpreviouspaperexam")} to="/student/createpreviouspaperexam" onClick={() => this.props.onClick()}>
                                                    <i className="fal fa-clipboard-list-check" title="JEE Mains" /> <span>JEE Mains 2022<Image src={gifImage} className="ml-2" alt="New" width="40" /></span>
                                                </Link>
                                            </Nav.Item>
                                        ) : ("")
                                    } */}

                                        {/*
                                        Cookies.get("examid") == 1 ? (
                                            this.props.isuserValid.userlevel == 1 ? 
                                            (
                                                this.props.isuserValid.ins_rapid_revision != true ? 
                                                (
                                                    <Nav.Item>
                                                        <Link className={this.getActiveClass("/student/rapidRevision/liveClasses")} to="/student/rapidRevision/liveClasses">
                                                            <i className="fal fa-video" title="Rapid Revision" /> <span>Rapid Revision</span><span className="label-bg">NEET 2021</span>
                                                        </Link>
                                                    </Nav.Item>
                                                )
                                                :
                                                ("")
                                            ) 
                                            :
                                            (
                                                <Nav.Item>
                                                <Link className={this.getActiveClass("/student/rapidRevision/liveClasses")} to="/student/rapidRevision/liveClasses">
                                                    <i className="fal fa-video" title="Rapid Revision" /> <span>Rapid Revision</span><span className="label-bg">NEET 2021</span>
                                                </Link>
                                                </Nav.Item>
                                            )
                                               
                                         ) : ("")
                                    */}

                                        {/* {this.props.isuserValid.ins_package_tab == true ? ("") : (<React.Fragment>
                                        {Cookies.get("student_userlevel") != 1 ? (<Nav.Item>
                                            <Link className={this.getActiveClass("/student/cbse")} to="/student/cbse">
                                                <i className="far fa-book-open" title="jee Repeater" /> <span>CBSE 2021</span>
                                            </Link>
                                        </Nav.Item>) : ("")}
                                    </React.Fragment>)
                                    } */}


                                        {/* <hr className="sidebar-divider" /> */}
                                        {/* {this.props.isuserValid.ins_doubts_tab == true ? ("") : (
                                    
                                    <Nav.Item>
                                        <Link className={this.getActiveClass("/student/previous-paper-analysis")} onClick={() => this.forumLink()} onClick={() => this.props.onClick()}>
                                            <i className="fal fa-paper-plane" title="Doubts App" /> <span>Doubts App</span>
                                        </Link>
                                    </Nav.Item>
                                )} */}


                                    </Accordion>

                                </Nav>
                            ) : (
                                <Nav className="navbar-nav menu">
                                    <Accordion defaultActiveKey={this.getAccordianActive()}>
                                        {
                                            Cookies.get("toggle") == "wrapper sidebar-enable" && Cookies.get("student_userlevel") == 2 ?
                                                (<h6 className='nav_type_header'>TEST PREP BUNDLE</h6>)
                                                :
                                                ("")
                                        }
                                        {
                                            <Nav.Item>
                                                <Link className={this.getActiveClass("/student/previouspaperexams")} to="/student/previouspaperexams" onClick={() => this.props.onClick()}>
                                                    <i className="fal fa-clipboard-list-check" title="Previous papers" /> <span>Previous Year Papers</span>
                                                </Link>
                                            </Nav.Item>
                                        }
                                        {
                                            Cookies.get("examid") == 2 ? (
                                                this.props.isuserValid.ins_mock_test == true ? ("") : (<Nav.Item>

                                                    <Link className={this.getActiveClass("/student/exams/test-series")} to="/student/exams/test-series" onClick={() => this.props.onClick()}>
                                                        <i className="fal fa-badge-check" title="Exams History" /> <span>Mock Tests with Analysis</span>
                                                    </Link>

                                                </Nav.Item>)

                                            ) : ("")
                                        }
                                        {
                                            Cookies.get("examid") == 1 ? (
                                                this.props.isuserValid.userlevel == 1 ?
                                                    (
                                                        this.props.isuserValid.ins_mock_test_nta != true ?
                                                            (
                                                                <Nav.Item>
                                                                    <Link className={this.getActiveClass("/student/exams/test-series/NewNTAPattern")} to="/student/exams/test-series/NewNTAPattern" onClick={() => this.props.onClick()}>
                                                                        <i className="fal fa-clipboard-list-check" title="New NTA Pattern" /> <span>Mock Tests with Analysis</span>
                                                                    </Link>
                                                                </Nav.Item>
                                                            )
                                                            :
                                                            ("")
                                                    )
                                                    :
                                                    (
                                                        <Nav.Item>
                                                            <Link className={this.getActiveClass("/student/exams/test-series/NewNTAPattern")} to="/student/exams/test-series/NewNTAPattern" onClick={() => this.props.onClick()}>
                                                                <i className="fal fa-clipboard-list-check" title="New NTA Pattern" /> <span>Mock Tests with Analysis</span>
                                                            </Link>
                                                        </Nav.Item>
                                                    )
                                            ) : ("")

                                        }
                                        {this.props.isuserValid.error_exam == true ? ("") : (<Nav.Item>
                                            <Link className={this.getActiveClass("/student/exams/error-exam")} to="/student/exams/error-exam">
                                                <i className="fas fa-exclamation-circle" title="Error Exam" /> <span>Error Exam</span>
                                            </Link>
                                        </Nav.Item>)}

                                        {this.props.isuserValid.ins_analysis_tab == true ? ("") : (
                                            <Nav.Item>
                                                <Link className={this.getActiveClass("/student/result-analysis")} to="/student/result-analysis" onClick={() => this.props.onClick()}>
                                                    <i className="fal fa-analytics" title="Result &amp; Analysis" /><span>Performance Analysis</span>
                                                </Link>
                                            </Nav.Item>

                                        )}

                                    </Accordion>

                                </Nav>
                            )
                        )

                    }
                </Scrollbars>

                <StartingModal show={this.state.StartingModalShow} onHide={this.onHide} isuserValid={this.props.isuserValid} />
                {
                    this.state.targetPopupData.pop_enabled == true ?
                        (
                            <StudentTargettingPopup
                                show={this.state.targetPopup}
                                onHide={this.onHide}
                                targetPopupData={this.state.targetPopupData}
                            />
                        )
                        :
                        ("")
                }
            </React.Fragment>
        )
    }
}

export default withRouter(compose(
    graphql(GET_CROSSSELLING_POPUP,
        {
            options: props => ({
                variables: {
                    mobile: Cookies.get("mobile"),
                    app_module_id: 1,
                    source: 2
                }
                ,
                fetchPolicy: "network-only"
            }), name: "getCrossSellingEnabled"
        }),
)(MobileAsideNavbar));